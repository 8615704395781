.calculatorWrapper {
  margin-bottom: var(--spacing-10);

  & > *:not(:last-child) {
    margin-bottom: var(--spacing-10);
  }
}

.link {
  > span {
    color: var(--neutral-700-pressed-default-text, --color-tarmac-grey-700);
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.2px;
    text-decoration-line: underline;
  }
}

.mainWrapper {
  max-width: 100%;
}

.formContainer {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  gap: var(--spacing-8);

  @media (--tablet) {
    flex-direction: column;
    gap: var(--spacing-6);
  }

  @media (--tablet-l) {
    gap: var(--spacing-8);
  }

  @media (--desktop) {
    gap: var(--spacing-8);
  }
}

.content {
  > div {
    margin-bottom: 0;
  }
  @media (--mobile) {
    width: 100%;
  }
  @media (--tablet-l) {
    max-width: 632px;
  }
}

.bottomFields {
  > div {
    margin: var(--spacing-3) 0 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media (--mobile) {
      flex-direction: column;
    }
  }
  > div > div {
    width: 100%;
  }
}

.summaryContainer {
  flex: 1 1 200px;
  & h5,
  & p {
    margin-bottom: var(--spacing-2);
    margin-top: 0;
  }

  & > *,
  & > p {
    margin-bottom: var(--spacing-5);
  }

  @media (--tablet) {
    max-width: calc(50% - 18px);
  }
}

.ctasContainer {
  text-align: center;

  & > :first-child {
    margin-bottom: var(--spacing-3);
    @media (--tablet) {
      margin-bottom: 0;
    }
  }
}

.summaryTitle {
  & [class*='typography'] {
    position: relative;
  }
}

.fakeSummary {
  height: 380px;
  position: relative;
}

.fakeButton {
  height: 43px;
  width: 100%;
  position: relative;
}

.fakeButtons {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-3);
  margin-bottom: var(--spacing-8) !important;
  max-width: 100%;
  position: relative;
}
.modalContainer {
  @media (--tablet) {
    width: 80%;
    height: 635px;
  }
  @media (--tablet-l) {
    width: 800px;
  }
}

.modalBody {
  overflow: auto;
}
.summaryTitleWrapper {
  display: flex;
  flex-direction: row;
}
