.wrapper {
  margin-bottom: 80px;
}

.body {
  padding-left: 0px;
}

.title {
  @media (--mobile) {
    margin: var(--spacing-8) 0 var(--spacing-3);
  }

  @media (--tablet) {
    margin: var(--spacing-10) 0 var(--spacing-3);
  }

  @media (--tablet-l) {
    margin: 60px 0 var(--spacing-3);
  }
}

.intro {
  @media (--mobile) {
    margin: 0 0 var(--spacing-4);
  }

  @media (--tablet) {
    margin: 0 0 var(--spacing-8);
  }

  @media (--tablet-l) {
    margin: 0 0 var(--spacing-10);
  }
}

.buttonGroup {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  gap: var(--spacing-2);
  margin: var(--spacing-8) 0;

  @media (--mobile) {
    grid-template-columns: 1fr;
  }

  @media (--tablet-l) {
    max-width: 468px;
  }

  @media (--desktop-l) {
    max-width: 632px;
  }
}

.vehicleInfoWrapper {
  padding: var(--spacing-4) 0 0;
  @media (--tablet-l) {
    display: none;
  }
}
