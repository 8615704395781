.title {
  font-weight: 700;
  @media (--desktop) {
    margin-top: var(--spacing-10);
  }
}

.intro {
  margin: 0 0 var(--spacing-2);
}

.accordion {
  padding: 0 !important;

  & div {
    box-shadow: none !important;
  }

  & > div:first-child {
    color: var(--color-neutral-700);
    font-size: 20px;
    font-weight: 700;
    padding: 0;
    padding: var(--spacing-6) 0;
  }

  &[data-is-open='true'] {
    border: 0;
    overflow: visible;
  }
}

.divider {
  color: var(--color-neutral-300);
  margin-bottom: 0 !important;
}

.description {
  margin-bottom: var(--spacing-10) !important;
}

.details {
  margin: 0;
}

.editButton {
  flex: 1;
  text-align: right;
  margin-right: var(--spacing-8);
  cursor: pointer;
}

.subtitle {
  font-weight: 700;
}

.description {
  margin: 0 0 var(--spacing-2);
}

.fieldGroup {
  margin: 0 1px;
  & > div:first-child {
    margin-top: var(--spacing-4);
  }
}

.fieldGroupAddress {
  margin: 0 1px;
  > div > div > div {
    margin: var(--spacing-3) 0;
  }
}

.license {
  margin: var(--spacing-4) 0 0;
}

.address {
  margin: 0 0 var(--spacing-1);
}

.collection {
  margin-top: var(--spacing-8);
}

.continueButton {
  margin-bottom: var(--spacing-6);
}

.backButton {
  margin: var(--spacing-8) 0;
}

.button {
  gap: 12px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;

  @media (--tablet-l) {
    max-width: 468px;
  }

  @media (--desktop-l) {
    max-width: 632px;
  }

  @media (--mobile-s) {
    grid-template-columns: 1fr;
  }
}

.nextSection {
  color: var(--color-tarmac-grey-300);
  font-weight: 700;
}

.whyDoWeNeedThisAccordion {
  border-bottom: 0 !important;
  padding-bottom: 0 !important;

  > div {
    justify-content: flex-start;
    padding: 0;
  }

  a,
  a:visited {
    color: var(--color-neutral-700);
  }
}

.yearsAtBank {
  margin-top: -20px;
}

.summaryLabel {
  margin-right: var(--spacing-2);
}

.vehicleInfoWrapper {
  padding-bottom: var(--spacing-4);
  @media (--tablet-l) {
    display: none;
  }
}
