.header {
  border-color: var(--color-neutral-50);
}

.modalBody {
  padding-top: var(--spacing-4);
  overflow-y: auto;
}

.divider {
  &[data-has-divider='true'] {
    border-bottom: 1px solid var(--color-neutral-50);
    margin-bottom: var(--spacing-3);
  }
}

.title {
  display: flex;
  align-items: center;
  margin-bottom: var(--spacing-3);

  & p {
    margin: 0;
  }

  & svg {
    margin-right: var(--spacing-2);
  }
}

.step {
  margin-bottom: var(--spacing-3);

  & p {
    margin: 0;
  }

  &:after {
    border-width: 1px;
    left: 11px;
    z-index: 0;
  }

  &[data-variant-color='primary'] {
    &:after {
      border-color: var(--color-primary-100);
    }
  }

  &[data-variant-color='success'] {
    &:after {
      border-color: var(--color-secondary-100);
    }
  }
}

.circle {
  background-color: var(--color-tarmac-grey-50);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  flex-wrap: wrap;
  margin-right: var(--spacing-4);
  padding: var(--spacing-1);
  z-index: 1;

  &[data-variant-color='primary'] {
    background-color: var(--color-primary-500);
  }

  &[data-variant-color='success'] {
    background-color: var(--color-success-500);
  }

  & img {
    width: 100%;
    height: 100%;
  }
}

.quoteUsps {
  margin-bottom: var(--spacing-4);

  & p {
    margin: var(--spacing-2) 0 var(--spacing-3);
  }
}

.quoteStep {
  display: flex;
  align-items: center;

  & p {
    margin: var(--spacing-1) 0;
  }
}
