.summarySection {
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (--mobile) {
    grid-template-columns: 1fr;
  }

  & > div {
    display: flex;
  }

  &[data-is-full-width='true'] {
    grid-template-columns: 1fr;

    & div {
      display: block;
    }
  }

  & p {
    margin: 0 0 var(--spacing-3) 0;
  }

  &:not(:last-child) {
    border-bottom: 1px solid var(--color-neutral-50);
  }
}

.summaryLabel {
  margin-right: var(--spacing-2);
}

.summaryItem {
  white-space: break-spaces;
}

.addressItem {
  margin: 0 !important;
}
