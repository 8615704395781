.card {
  margin: var(--spacing-6) 0;

  &[data-variant='info'] {
    color: var(--color-info-700);
    padding: var(--spacing-1);
  }

  & .cardHeader {
    display: flex;
    flex-direction: row;
  }

  & .icon {
    background-color: var(--color-mica-blue-400);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 54px;
    width: 54px;

    & svg {
      fill: var(--color-championship-white);
    }
  }

  & .status {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: var(--spacing-4);

    & p {
      margin: 0;
    }

    & p:first-child {
      font-size: 14px;
    }

    & p:last-child {
      color: var(--color-mica-blue-500);
      font-size: 20px;
    }
  }

  & .button {
    margin: 0;
    max-width: 100%;

    @media (--tablet) {
      max-width: 33%;
    }

    & button {
      margin: 0;
      max-width: none;
    }
  }
}
