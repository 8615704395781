.intro {
  margin: 0 0 var(--spacing-8);
}

.subtitle {
  margin: 0;
}

.fields {
  > div {
    margin: var(--spacing-4) 0 var(--spacing-6);
  }
}

.collectionTitle {
  font-size: 20px;
  margin: var(--spacing-2) 0 var(--spacing-4);
}

.collectionIntro {
  margin: 0;
}

.carLocation {
  margin: 0 0 var(--spacing-7);
}

.radio {
  > div {
    margin: var(--spacing-6) 0 var(--spacing-8);
  }

  & label:first-child {
    margin-bottom: var(--spacing-6);
  }
}
