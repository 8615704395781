.accordionTitle {
  flex: 1;
  text-align: right;
}

.terms,
.accordionTitle {
  & a {
    color: var(--color-neutral-700);
  }
}
