.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-3);
  margin-top: var(--spacing-10);
}

.title {
  margin: 0 0 var(--spacing-1) 0;
}

.description {
  margin: 0 0 var(--spacing-2) 0;
}

.button {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;

  @media (--mobile) {
    grid-template-columns: 1fr;
  }

  & button {
    margin: 0;
  }
}
