.reviewTitleInfo {
  display: flex;
  justify-content: space-between;
  margin-top: var(--spacing-8);
}
.reviewTitleInfoLeft {
  text-align: left;
}

.reviewInfoModifier {
  text-align: right;
  cursor: pointer;
}
.reviewTitleInfoModifier {
  cursor: pointer;
}
.reviewDealerAddress {
  margin-top: var(--spacing-8);
}
.summaryDeliveryTerms {
  padding: var(--spacing-6) var(--spacing-1) var(--spacing-1) var(--spacing-10);
  background-color: var(--color-mica-blue-50);
  @media (--mobile) {
    padding: var(--spacing-6) var(--spacing-6) var(--spacing-1);
    margin: 0;
  }
}

.summaryContactTerms {
  padding: var(--spacing-6) var(--spacing-6) var(--spacing-1) var(--spacing-10);
  background-color: var(--color-tarmac-grey-50);
  @media (--mobile) {
    padding: var(--spacing-6) var(--spacing-6) var(--spacing-1);
    margin: 0;
  }
}
