.stage {
  @media (--mobile) {
    padding-top: var(--spacing-6);
  }

  &:global(.statusCard) {
    margin-bottom: 0 !important;
  }
}

.text {
  flex: 0 0 calc(100% - 64px);
}

.circle {
  margin-right: var(--spacing-4);
  fill: var(--color-tarmac-grey-50);

  &[data-is-first-step='true'] {
    fill: var(--color-mica-blue-500);
  }
}

.buttonGroup {
  gap: var(--spacing-3);

  & button {
    margin-bottom: 0;
  }
}

.summaryCard {
  display: block;

  @media (--tablet-l) {
    display: none;
  }
}

.sidebar {
  display: none;

  @media (--tablet-l) {
    display: block;
  }
}
