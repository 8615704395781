.accordion {
  & div {
    box-shadow: none !important;
  }
}

.accordionTitle {
  flex: 1;
  text-align: right;
}

.terms {
  margin-top: var(--spacing-8);
  h6 {
    margin: 0 0 var(--spacing-2) 0;
  }

  p {
    margin: 0;
  }

  > div {
    margin-top: var(--spacing-3);
  }
}

.terms,
.accordionTitle {
  & a {
    color: var(--color-neutral-700);
  }
}

.financingTable {
  border: 1px solid var(--color-primary-700);

  .header {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: var(--spacing-5) var(--spacing-6);
    background-color: var(--color-primary-100);

    @media (--tablet) {
      flex-direction: row;
    }
  }

  .financeOption {
    h3,
    p {
      margin: 0;
      display: inline-block;
      color: var(--color-primary-700);
    }

    h3:first-child {
      margin-right: var(--spacing-6);
    }
  }

  .editFinanceDeal {
    padding-top: var(--spacing-3);

    a,
    a:visited {
      color: var(--color-neutral-700);
    }

    @media (--tablet) {
      align-self: center;
      padding-top: 0;
    }
  }

  .contentWrapper {
    display: flex;
    flex-direction: column;
    padding-top: var(--spacing-3);
    padding-bottom: var(--spacing-3);
    background-color: var(--color-primary-50);

    @media (--tablet) {
      flex-direction: row;
      > div:first-child {
        border-right: 1px solid var(--color-primary-100);
      }
    }
  }

  .content {
    display: flex;
    flex: 1;
    justify-content: space-between;
    flex-direction: column;
    padding-left: var(--spacing-6);
    padding-right: var(--spacing-6);
  }

  .row {
    display: flex;
    justify-content: space-between;

    div {
      flex: 1;
    }

    div:first-child {
      flex-grow: 2;

      &.lender {
        flex-grow: 0;
      }
    }

    div:nth-child(2) {
      flex-grow: 1;
      text-align: end;
    }
  }
}

.clickable {
  cursor: pointer;
}
.vwfsterms {
  margin-top: -30px;

  & label div {
    display: inline !important;
  }
}

.vehicleInfoWrapper {
  padding-bottom: var(--spacing-4);
  @media (--tablet-l) {
    display: none;
  }
}
