.financeModal {
  max-width: 870px;
}

.financeModalDescription {
  max-width: 870px;
  margin: 0 auto;
  font-size: 16px;
  line-height: 26px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: var(--spacing-6);
  margin-bottom: var(--spacing-5);

  @media (--tablet) {
    flex-direction: row;
  }
}

.financeModalContent {
  display: flex;
  flex-direction: column;
  flex-wrap: none;
  justify-content: center;

  @media (--tablet) {
    flex-direction: row;
  }
}
.financeModalOption {
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  padding-top: var(--spacing-1);
  background-color: white;
  border-top: 1px solid var(--color-heycar-mint);

  &:last-child {
    padding-bottom: var(--spacing-5);
  }

  @media (--tablet) {
    max-width: 345px;
    &:not(:last-child) {
      margin-right: var(--spacing-8);
    }
  }
}
.financeModalOptionTitle {
  margin-top: var(--spacing-5);
  span {
    font-size: 20px;
  }
}

.financeModalOptionSubtitle {
  margin-top: var(--spacing-3);
  line-height: 26px;
}

.financeModalPaymentPlan {
  background-color: var(--color-mica-blue-50);
  padding: var(--spacing-5);
  width: 100%;
  margin: var(--spacing-3) 0;

  @media (--tablet) {
    width: 96%;
    margin-left: 0;
  }
}

.financeModalPaymentPlanTitle {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: var(--spacing-3);
}

.financeModalImage {
  position: relative;
  aspect-ratio: 3/1;
  width: 100%;
  max-width: 365px;
  margin: 10px 0;
  & img {
    height: unset !important;
  }
}

.financeModalUspsWrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-3);
  margin-top: 10px;
  font-size: 14px;
  justify-content: flex-start;
  align-items: flex-start;

  @media (--tablet) {
    width: 820px;
  }

  @media (--tablet-l) {
    width: 100%;
  }

  & .uspItem {
    display: flex;
    gap: var(--spacing-3);
    align-items: center;
  }

  & .uspIcon {
    position: relative;
    aspect-ratio: 1/1;
    height: 20px;
  }
}

.financeModalLinksFooter {
  margin-bottom: var(--spacing-7);

  @media (--tablet-l) {
    margin-bottom: 0;
  }
}

.financeModalGuideLinks {
  display: block;
  margin-bottom: var(--spacing-3);
  color: inherit;
  text-decoration-line: underline;
}

.financeModalFooter {
  padding-top: var(--spacing-2);
  padding-bottom: var(--spacing-8);
}
