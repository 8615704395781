.container {
  padding: 12px;
  background-color: var(--color-primary-50);
  list-style: none;
}

.summary {
  list-style: none;
  padding: 0;
  margin: 0;
  color: var(--color-primary-600);

  > li {
    border-bottom: 1px var(--color-tarmac-grey-300);
    font-size: 10px;

    &:first-child {
      padding: 8px 0;
    }
    &:last-child {
      border-bottom: none;
    }
  }

  & li.instalments {
    margin: 0;
    display: flex;
    font-weight: 700;
    font-size: 16px;
    & span:first-child {
      flex-basis: 70%;
    }
    & span:last-child {
      text-align: right;
      flex-basis: 30%;
    }
  }
  & li > span > .monthlyRate {
    min-width: 188px;
    white-space: wrap;
    font-size: 12px;
  }

  & li.additionalServices {
    & span:first-child {
      flex-basis: 60%;
    }
  }

  & li > ul {
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 12px;
    & li {
      display: flex;
      justify-content: space-between;
      margin: 10px 0;
      & span:first-child {
        flex-basis: 50%;
      }
      & span:last-child {
        font-weight: 500;
        text-align: right;
      }
    }
  }
}

.appFeeContent {
  margin-left: 14px;
  white-space: nowrap;
}

.checkoutSummaryContent {
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  background-color: white;
  border: 1px solid var(--color-primary-500);
  margin-top: 16px;
  height: 10%;

  @media (--tablet-s) {
    &:not(:last-child) {
      margin-right: var(--spacing-3);
    }
  }
}

.checkoutSummaryContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: none;
  @media (--tablet-s) {
    flex-direction: row;
  }
}
.buttonWrapper {
  background-color: var(--color-championship-white);
  padding: 10px;
  & button {
    width: 100%;
    margin-bottom: 10px;
  }
}
.divider {
  border-bottom: 1px solid var(--color-primary-500);
  margin: var(--spacing-4) 0 var(--spacing-4);
}
.termsContainer,
.nonAvailableContent {
  padding: 12px;
  list-style: none;
}
.terms {
  font-size: 12px;
  line-height: 18px;
  padding: 0;
  margin: 0;
  color: var(--color-neutral-500);
}

.nonAvailableContent {
  & h4 {
    color: var(--color-primary-500);
    margin: 0;
  }

  & .values {
    display: inline;

    & p {
      display: inline;
      margin: 0;
    }
  }
}

.perMonth {
  font-size: 12px;
  color: var(--color-primary-400);
}
